<template>
  <div class="news_index">
    <a-row :gutter="30">
      <a-col :span="18">
        <router-view :key="$route.path"></router-view>
      </a-col>
      <a-col :span="6">
        <div class="right">
          <div class="search">
            <input
              type="text"
              placeholder="请输入要搜索的内容"
              v-model="keyword"
              name="keyword"
              value=""
            />
            <a-button type="primary" @click="search">搜索</a-button>
          </div>
          <div class="title">热门推荐</div>
          <div class="hot-list">
            <div class="item" v-for="(item, index) in list" :key="index">
              <router-link
                :to="{ name: 'news_detail', params: { id: item.articleId } }"
              >
                <span>{{ index + 1 }}</span
                ><b>{{ item.title }}</b>
              </router-link>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/news";
export default {
  data() {
    return {
      keyword: "",
      list: [],
    };
  },
  components: {},
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.getList();
    },
    getList() {
      Api.recommend({ newsTitle: this.keyword }).then((res) => {
        this.list = res;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.right {
  .search {
    border-bottom: 2px solid #008840;
    padding-bottom: 10px;
  }
  .title {
    text-align: center;
    line-height: 2em;
    font-size: 18px;
    color: #333;
  }
  a {
    color: #333;
    text-decoration: none;
  }
  .hot-list {
    line-height: 2.5em;
    font-size: 16px;
    .item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:nth-child(-n + 3) {
        span {
          color: #008840;
        }
      }
      span {
        padding: 0 15px 0 5px;
        font-style: italic;
        font-weight: bold;
        color: #a4a4a4;
      }
    }
  }
}
</style>
