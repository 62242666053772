import request from "@/utils/request"

export default {
    list(params = {}) {
        return request.get("/news/list", {
            params
        })
    },
    detail(id) {
        return request.get(`/news/${id}`)
    },
    recommend(params) {
        return request.get("/getNewsHotRecommend", {
            params
        })
    },
    click(newsId) {
        return request.post("/news/hitsCurrentNews?newsId=" + newsId)
    }
}